<template>
  <div class="school-bank">
    <div class="mt-2 row justify-content-center">
      <div class="col-12">
        <b-card title="Bank">
          <div class="mb-1">
            <b>Please only add a bank if you intend to accept online fee payments.</b>
          </div>
          <validation-observer
            ref="bankForm"
            v-slot="{invalid}"
          >
            <div class="row">
              <lenon-responsive>
                <lenon-select
                  v-model="bankToSave.bank_code"
                  rules="required"
                  label="Bank Name"
                  name="bank_code"
                  :options="banks"
                  label-name="name"
                  value-name="code"
                  placeholder="Select your Bank"
                />
              </lenon-responsive>
              <lenon-responsive>
                <lenon-input
                  v-model="bankToSave.account_number"
                  rules="required"
                  label="Account Number"
                  name="account_number"
                  placeholder="Enter your bank account #"
                />
              </lenon-responsive>
              <lenon-responsive>
                <lenon-input
                  v-model="bankToSave.account_name"
                  rules="required"
                  label="Account Holder Name"
                  name="account_name"
                  placeholder="eg. Prince Osei"
                />
              </lenon-responsive>
            </div>
            <div class=" text-bolder text-danger mb-1">
              <small><b>All withdrawals and payouts goes to this bank, change anytime.</b></small><br>
            </div>
            <div class="text-bolder">
              <div><b>This bank account is for the purposes of receiving payments made by parents through the mobile app, you need to understand the following conditions before enabling online payments.</b></div><br>
            </div>
            <div class="mb-2 text-bolder">
              <ul>
                <li>The Payment Gateway Lenon Uses charges a percentage of 1.95 for every transaction. If you do not want to incur these charges, check <b>Apply Charges</b> under school information to add these charges to fee amounts for online payments.</li>
                <li>When parents make payments, the money reflects in the provided bank account within 48 hours after payment is made.</li>
              </ul>
              If these conditions do not favor the operations of your school, you can simply uncheck <b>Online Payments</b> under school information.
            </div>
            <div><b>Note:</b> <b>Lenon does not charge any fee for any payments made with the mobile app.</b></div><br>
            <lenon-button
              name="update-bank"
              label="Save"
              class="float-right"
              :disabled="invalid"
              :loading="bankFormLoading"
              @onClick="saveBank()"
            />
          </validation-observer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { ValidationObserver } from 'vee-validate'
import LenonResponsive from '@/lenon/components/LenonResponsive.vue'
import { SAVE_BANK_M } from '@/graphql/mutations'

export default {
  name: 'SchoolBank',
  components: {
    LenonResponsive,
    LenonButton,
    LenonInput,
    LenonSelect,
    BCard,
    ValidationObserver,
  },
  mixins: [showToast],
  data() {
    return {
      bankFormLoading: false,
      bankToSave: {
        bank_code: null,
        account_number: null,
        account_name: null,
      },
    }
  },
  computed: {
    bank() {
      return this.$store.getters['auth/bank']
    },
    banks() {
      return this.$store.getters['auth/banks']
    },
  },
  mounted() {
    this.bankToSave = { ...this.bankToSave, ...this.bank }
  },
  methods: {
    saveBank() {
      this.bankFormLoading = true
      delete this.bankToSave.id
      this.$apollo.mutate({
        mutation: SAVE_BANK_M,
        variables: { input: this.bankToSave },
      }).then(res => {
        this.bankFormLoading = false
        this.$store.commit('auth/updateBank', res.data.saveBank)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.bankFormLoading = false
        this.showError('Something went wrong, Make sure details are valid, otherwise contact support.')
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div id="school">
    <lenon-form-layout
      title="School"
      submit-text="Save"
      :show-loading="updatingForm"
      :show-reset="false"
      submit-button-name="update-school"
      @onSubmit="update"
    >
      <template slot="form-header">
        <div class="d-flex justify-content-center mb-2">
          <div class="d-flex flex-column justify-content-start">
            <div class="text-center mb-2">
              <lenon-profile-photo
                v-show="!updatingLogo && logoUrl"
                size="80"
                :photo="logoUrl"
              />
              <b-avatar
                v-show="!updatingLogo && !logoUrl"
                size="80px"
                variant="light-primary"
              />
              <b-spinner
                v-show="updatingLogo"
                style="width: 80px; height: 80px;"
                variant="primary"
              />
            </div>
            <lenon-file-button
              label="Logo"
              :loading="updatingLogo"
              @onFileUpload="uploadLogo"
            />
          </div>
        </div>

      </template>
      <template slot="form-elements">
        <div class="col-md-4">
          <lenon-input
            v-model="school.name"
            name="name"
            label="Name"
            rules="required"
          />
        </div>
        <div class="col-md-4">
          <lenon-date-picker
            v-model="school.date_established"
            name="date_established"
            label="Date Established"
            :show-label="true"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="school.slogan"
            name="slogan"
            label="Slogan"
            placeholder="School Slogan"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="school.phone"
            name="phone"
            label="Phone"
            rules="required|max_length:15"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="school.email"
            name="email"
            label="Email"
            rules="required|email"
          />
        </div>
        <div
          v-if="hasModule('finance')"
          class="col-md-12"
        >
          <b-form-checkbox
            v-model="school.accept_online_payments"
            v-b-tooltip.hover="'If this is checked, parents will be able to pay fees online using the parent app.'"
          >
            Online Payments
          </b-form-checkbox>
        </div>
        <div class="col-12 mt-1">
          <lenon-text-area
            v-model="school.about"
            name="about"
            label="About your School"
          />
        </div>
        <div class="col-md-6 mt-1">
          <lenon-multi-select
            v-model="school.creche_classes"
            name="creche_classes"
            label="Creche Classes (These classes mostly do not partake in end of term examinations)"
            label-name="name"
            value-name="id"
            :options="classes"
          />
        </div>
        <div class="col-md-12 mt-2">
          <lenon-button @onClick="showPicker" variant="outline-primary" label="School Location" icon="MapPinIcon" tool-tip-text="Select your school's location, this is required for bus transportation purposes."/>
        </div>
        <div class="col-12">
          <error-display :error="error" />
        </div>
      </template>
    </lenon-form-layout>
    <location-picker @onPositionChange="setLocation" @onClose="hidePicker" :location="location" :opened="locationPickerOpened"/>
  </div>
</template>

<script>
import {
  BAvatar, BSpinner, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import LenonFormLayout from '@/lenon/components/layouts/LenonFormLayout.vue'
import LenonResponsive from '@/lenon/components/LenonResponsive.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonFileButton from '@/lenon/components/LenonFileButton.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import { UPDATE_SCHOOL_M, UPLOAD_SCHOOL_LOGO_M } from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'
import LenonButton from '@/lenon/components/LenonButton'
import LocationPicker from '@/views/transportation/settings/pickup/LocationPicker'

export default {
  name: 'School',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    LocationPicker,
    LenonButton,
    LenonMultiSelect,
    LenonProfilePhoto,
    ErrorDisplay,
    LenonTextArea,
    LenonDatePicker,
    LenonFileButton,
    LenonInput,
    LenonResponsive,
    LenonFormLayout,
    BAvatar,
    BSpinner,
    BFormCheckbox,
  },
  mixins: [showToast],
  data() {
    return {
      error: {},
      school: {
        id: null,
        name: null,
        alias: null,
        slogan: null,
        about: null,
        email: null,
        phone: null,
        date_established: null,
        accept_online_payments: null,
        apply_charges: null,
        creche_classes: [],
        lng: null,
        lat: null,
      },
      location:{
        lng: null,
        lat: null,
      },
      updatingLogo: false,
      updatingForm: false,
      locationPickerOpened: false,
    }
  },
  computed: {
    // eslint-disable-next-line class-methods-use-this
    logoUrl() {
      return this.$store.getters['auth/schoolLogo']
    },
    schoolInfo() {
      return this.$store.getters['auth/school']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  watch: {
    schoolInfo(school) {
      this.school = { ...school, creche_classes: this.schoolInfo.creche_classes.map(id => this.classes.find(c1 => c1.id === id)) }
      this.location.lat = this.school.lat
      this.location.lng = this.school.lng
    },
  },
  mounted() {
    this.school = {
      ...this
        .schoolInfo,
      creche_classes: this.schoolInfo.creche_classes.map(id => this.classes.find(c1 => c1.id === id)),
    }
  },
  methods: {
    showPicker(){
      this.locationPickerOpened = true
    },
    setLocation(val){
      this.school.lng = val.lng
      this.school.lat = val.lat
    },
    hidePicker(){
      this.locationPickerOpened = false
    },
    hasModule(module) {
      const modules = this.$store.getters['auth/schoolModules']
      return modules.includes(module)
    },
    uploadLogo(e) {
      this.updateLogo(e.file)
    },
    update() {
      this.error = {}
      delete this.school.logo
      delete this.school.verified
      delete this.school.verification_document
      delete this.school.verification_code
      delete this.school.new_alias
      delete this.school.active_sms
      delete this.school.trial_ends
      delete this.school.activated_sms_status
      delete this.school.modules
      this.updatingForm = true
      this.$apollo.mutate({
        mutation: UPDATE_SCHOOL_M,
        variables: {
          input: {
            ...this
              .school,
            creche_classes: this.school.creche_classes.map(c => c.id),
          },
        },
      }).then(res => {
        this.updatingForm = false
        this.$store.commit('auth/updateSchool', res.data.updateSchool)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.updatingForm = false
        console.log(err)
        this.error = err
        this.showError('Something went wrong, please try again')
      })
    },
    updateLogo(logo) {
      this.updatingLogo = true
      this.$apollo.mutate({
        mutation: UPLOAD_SCHOOL_LOGO_M,
        variables: { input: { logo } },
      }).then(res => {
        this.updatingLogo = false
        this.$store.commit('auth/updateLogo', res.data.uploadSchoolLogo)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.updatingLogo = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
  },
}
</script>

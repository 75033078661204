<template>
  <div id="address">
    <lenon-form-layout
      title="Address"
      submit-text="Save"
      :show-loading="updatingForm"
      :show-reset="false"
      submit-button-name="update-address"
      @onSubmit="update"
    >
      <template slot="form-elements">
        <div class="col-md-4">
          <lenon-select
            v-model="address.country_id"
            name="country"
            label="Country"
            rules="required"
            label-name="name"
            value-name="id"
            :options="countries"
          />
        </div>
        <div class="col-md-4">
          <lenon-select
            v-model="address.region_id"
            name="region"
            label="Region"
            rules="required"
            label-name="name"
            value-name="id"
            :options="filteredRegions"
          />
        </div>
        <div class="col-md-4">
          <lenon-select
            v-model="address.city_id"
            name="city"
            label="City"
            rules="required"
            label-name="name"
            value-name="id"
            :options="filteredCities"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="address.town"
            name="town"
            label="Town"
            placeholder="Town"
            rules="required|max_length:100"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="address.box_address"
            name="box_address"
            label="Box Address"
            placeholder="Box Address"
            rules="max_length:100"
          />
        </div>
      </template>
    </lenon-form-layout>
  </div>
</template>

<script>
import LenonFormLayout from '@/lenon/components/layouts/LenonFormLayout.vue'
import LenonResponsive from '@/lenon/components/LenonResponsive.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { UPDATE_ADDRESS_M } from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'

export default {
  name: 'SchoolAddress',
  components: {
    LenonSelect,
    LenonInput,
    LenonResponsive,
    LenonFormLayout,
  },
  mixins: [showToast],
  data() {
    return {
      address: {
        id: null,
        country_id: null,
        region_id: null,
        city_id: null,
        town: null,
        box_address: null,
      },
      updatingForm: false,
    }
  },
  computed: {
    schoolAddress() {
      return this.$store.getters['auth/address']
    },
    countries() {
      return this.$store.getters['auth/countries']
    },
    regions() {
      return this.$store.getters['auth/regions']
    },
    cities() {
      return this.$store.getters['auth/cities']
    },
    filteredRegions() {
      return this.regions.filter(r => +r.country_id === +this.address.country_id)
    },
    filteredCities() {
      return this.cities.filter(c => +c.region_id === +this.address.region_id)
    },
  },
  watch: {
    schoolAddress(address) {
      this.address = { ...address } || this.address
    },
  },
  mounted() {
    this.address = { ...this.schoolAddress } || this.address
  },
  methods: {
    update() {
      this.updatingForm = true
      delete this.address.id
      this.$apollo.mutate({
        mutation: UPDATE_ADDRESS_M,
        variables: { input: this.address },
      }).then(res => {
        this.updatingForm = false
        this.$store.commit('auth/updateAddress', res.data.updateAddress)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.updatingForm = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
  },
}
</script>
